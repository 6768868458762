

import React from 'react'
import SignedInLinks from './SignedInLinks'
import SignedOutLinks from './SignedOutLinks'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { compose } from 'redux';
import MenuIcon from '../layout/icons/menu';
import IconButton from "@material-ui/core/IconButton";

const drawerWidth = 100;

const styles = theme => ({
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    marginLeft: drawerWidth,
    display: "block",
    [theme.breakpoints.up("sm")]: {
      // width: `calc(100% - ${drawerWidth}px)`,
      // background: "transparent",
      // boxShadow:'none',
      // color : '#000'
      display: "none"
    }
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  }
});

const Navbar = (props) => {
  const { classes, theme } = props;
  const { auth, profile } = props;
  const links = auth.uid ? <SignedInLinks profile={profile} /> : <SignedOutLinks />;
  
  //boxShadow : 'inset 0 0 0 0 #dbe2e8, 0 2px 1px 0 rgba(47,61,73,.05)'
  return (
    <React.Fragment>
        <AppBar className={classes.appBar} position="fixed" color="primary" >
            <Toolbar>
            <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={props.handleDrawerToggle}
                    className={classes.menuButton}
                    >
                    <MenuIcon viewBox="0 0 32 32" height="16" width="16" />
                    </IconButton>
                {/* {links} */}
            </Toolbar>
        </AppBar>
        
    </React.Fragment>

  )
}

const mapStateToProps = (state) => {
  // console.log(state);
  return{
    auth: state.firebase.auth,
    profile: state.firebase.profile
  }
}
export default compose(
  connect(mapStateToProps),
  withStyles(styles, { withTheme: true })
  )(Navbar);
