const initState = {
    loading: false,
    error: null,
    items: [],
    lesson: {},
    listeners: [], //this setup to stop listenning to schedule changes when user navigates away from the schedule component ,
    twilioToken: null
};

const scheduleReducer = (state = initState, action) => {
    switch (action.type) {
        case "FETCH_USER_SCHEDULE_BEGIN":
        case "FETCH_USER_SINGLE_SCHEDULE_BEGIN":
        case "CREATE_SCHEDULE_BEGIN":
        case "GET_TWILIO_TOKEN_BEGIN":
        case "CANCEL_SCHEDULE_BEGIN":  
        case "EDIT_RECURRING_SCHEDULE_BEGIN":  
            return {
                ...state,
                loading: true,
                error: false,
            };
        case "FETCH_USER_SCHEDULE_SUCCESS":
            return { ...state, items: [...action.items], listeners: [...action.listeners], loading: false, error: null };
        case "FETCH_USER_SCHEDULE_ERROR":
        case "FETCH_USER_SINGLE_SCHEDULE_ERROR":
        case "CREATE_SCHEDULE_ERROR":
        case "DELETE_SCHEDULE_ERROR":
        case "START_LESSON_ERROR":  
        case "CANCEL_SCHEDULE_ERROR":
        case "EDIT_RECURRING_SCHEDULE_ERROR":    
            return { ...state, loading: false, error: action.error };
        case "FETCH_USER_SINGLE_SCHEDULE_SUCCESS":
            return { ...state, lesson: action.lesson, listeners: [...action.listeners], loading: false, error: null };
        case "CREATE_SCHEDULE_SUCCESS":
            return { ...state, error: false, loading: false };
        case "DELETE_SCHEDULE_SUCCESS":
            return { ...state, error: false, loading: false };
        case "START_LESSON_SUCCESS":
            return { ...state, error: false, loading: false };
        case "GET_TWILIO_TOKEN_SUCCESS":
            console.log("GET_TWILIO_TOKEN_SUCCESS");
            return { ...state, loading: false, error: false, twilioToken: action.token };
        case "GET_TWILIO_TOKEN_ERROR":
            return { ...state, loading: false, error: action.error, twilioToken: null };
        case "END_LESSON_SUCCESS":
            return { ...state, loading: false, error: false, twilioToken: null };
        case "CANCEL_SCHEDULE_SUCCESS": 
        case "EDIT_RECURRING_SCHEDULE_SUCCESS":  
            //we keep loading true here because once the changes are made the realtime data will update the state automatically and the loading state will be updated 
            return { ...state, loading: true, error: null };    
        default:
            return state;
    }
};

export default scheduleReducer;
