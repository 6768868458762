import moment from 'moment-timezone';
import { userPrivates } from './userActions';
import {unSubListeners} from "./generalActions";

export const signIn = (credentials) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();

        firebase.auth().signInWithEmailAndPassword(
            credentials.email,
            credentials.password
        ).then((e) => {
            dispatch({ type: 'LOGIN_SUCCESS', uid: e.user.uid });
            dispatch(userPresence());
            dispatch(userPrivates());
        }).catch((err) => {
            dispatch({ type: 'LOGIN_ERROR', err });
        });

    }
}

export const signOut = () => {
    return async (dispatch, getState, { getFirebase }) => {
        
        dispatch({ type: 'SIGN_OUT_BEGIN' })

        //in the userPresence after we update the user we log the user out
        //we need to do that there because we need our auth to be in place while we access the db because of our DB auth rules
        dispatch(userPresence());
    }
}



export const signUp = (newUser) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        const firestore = getFirestore();
        let response = {}
        firebase.auth().createUserWithEmailAndPassword(
            newUser.email,
            newUser.password
        ).then(resp => {
            response = resp;
            return firestore.collection('users').doc(resp.user.uid).set({
                firstName: newUser.firstName,
                lastName: newUser.lastName,
                uid: resp.user.uid,
                isTeacher: newUser.isTeacher,
                isStudent: newUser.isStudent,
                uid: resp.user.uid,
                avatarURL: 'https://firebasestorage.googleapis.com/v0/b/camrata-app.appspot.com/o/avatars%2Fdefault.jpg?alt=media&token=abd3ad07-e8d2-4190-a3aa-f02f26ea8c34',
                timezone : moment.tz.guess(true)
            });
        }).then(() => {
            dispatch({ type: 'SIGNUP_SUCCESS', uid: response.user.uid });
            dispatch(userPresence());
            dispatch(userPrivates());
        }).catch((err) => {
            console.log(err)
            dispatch({ type: 'SIGNUP_ERROR', err });
        });
    }
}

export const updateCredentials = (credentials) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        const firestore = getFirestore();
        const user = firebase.auth().currentUser;
        
        dispatch({ type: 'PASSWORD_UPDATE_BEGIN' });
        
        user.updatePassword(credentials.password1).then(function() {
            dispatch({ type: 'PASSWORD_UPDATE_SUCCESS' });
          }).catch(function(error) {
          
            dispatch({ type: 'PASSWORD_UPDATE_ERROR',error });
        });
    }
}



export const userPresence = () => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        //wait a little for firebase to do its thing
        setTimeout(() => {
            const firebase = getFirebase();
            var uid = getState().firebase.auth.uid;
            var userLoggedOut = getState().auth.logout
            
            
            
            if (uid) {
                //console.log("firebase",firebase)
                // Create a reference to this user's specific status node.
                // This is where we will store data about being online/offline.
                var userStatusDatabaseRef = firebase.database().ref('/status/' + uid);
                var userStatusFirestoreRef = firebase.firestore().doc('/status/' + uid);
                // We'll create two constants which we will write to
                // the Realtime database when this device is offline
                // or online.
                var isOfflineForDatabase = {
                    state: 'offline',
                    last_changed: firebase.database.ServerValue.TIMESTAMP,
                    uid : uid
                };

                var isOnlineForDatabase = {
                    state: 'online',
                    last_changed: firebase.database.ServerValue.TIMESTAMP,
                    uid : uid
                };

                var isOfflineForFirestore = {
                    state: 'offline',
                    last_changed: firebase.firestore.FieldValue.serverTimestamp(),
                    uid : uid
                };

                var isOnlineForFirestore = {
                    state: 'online',
                    last_changed: firebase.firestore.FieldValue.serverTimestamp(),
                    uid : uid
                };

                // Create a reference to the special '.info/connected' path in
                // Realtime Database. This path returns `true` when connected
                // and `false` when disconnected.
                firebase.database().ref('.info/connected').on('value',  function(snapshot) {
                    // If we're not currently connected, don't do anything.
                    if (snapshot.val() === false) {
                        userStatusFirestoreRef.set(isOfflineForFirestore);

                        return;
                    };

                    // If we are currently connected, then use the 'onDisconnect()'
                    // method to add a set which will only trigger once this
                    // client has disconnected by closing the app,
                    // losing internet, or any other means.
                    userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(async function() {
                        // The promise returned from .onDisconnect().set() will
                        // resolve as soon as the server acknowledges the onDisconnect()
                        // request, NOT once we've actually disconnected:
                        // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

                        // We can now safely set ourselves as 'online' knowing that the
                        // server will mark us as offline once we lose connection.
                        userStatusFirestoreRef.set(isOnlineForFirestore);

                        userStatusDatabaseRef.set(isOnlineForDatabase);
                        if (userLoggedOut) {
                            const rdb = await userStatusDatabaseRef.set(isOfflineForDatabase);
                            const fdb = await userStatusFirestoreRef.set(isOfflineForFirestore);

                            //LOG THE USER OUT 
                            firebase.auth().signOut().then( async () => {
                                
                                let authListeners = getState().auth.listeners;
                                let userListeners = getState().user.listeners;
                                
                                if(authListeners){
                                    dispatch(unSubListeners(authListeners));
                                }
                                if(userListeners){
                                    dispatch(unSubListeners(userListeners));
                                }
                                
                                dispatch({ type: 'SIGN_OUT_SUCCESS' });
                                dispatch({ type: 'RESET_APP' })
                            });
                            
                        }

                    });
                });

                if(!userLoggedOut){
                    //change UI when user status changes 
                    let listeners = [];
                    const privates = getState().user.privates;
                    
                    if(privates.contacts){
                        const contactIDs = privates.contacts.userIDs;
                        const unsub = firebase.firestore().collection('status')
                        .where(firebase.firestore.FieldPath.documentId(), "in", contactIDs)
                        .where('state', '==', 'online')
                        .onSnapshot(function(snapshot) {
                            var changedDocs = snapshot.docChanges();
                            changedDocs.forEach(function(change) {
                                
                                listeners.push(unsub);
                                
                                if (change.type === 'added') {
                                    //var msg = 'User ' + change.doc.id + ' is online.';
                                    dispatch({ type: 'SET_PRESENCE', payload: { id: change.doc.id, status: 'online' }, listeners: listeners  });
                                }
                                if (change.type === 'removed') {
                                    //var msg = 'User ' + change.doc.id + ' is offline.';
                                    dispatch({ type: 'SET_PRESENCE', payload: { id: change.doc.id, status: 'offline' }, listeners: listeners  });
                                }
                                //console.log(change,msg);
                            });
                        });
                    }
                    
                }
            }
        }, 500);


    };
}

