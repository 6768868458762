const initState = {
    loading: false,
    error: null,
    items : {},
    newChatID : null ,
    listeners: []
}

export const chatReducer = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_USER_CHATS_BEGIN':
            return {
                ...state,
                loading : true,
                error : false,
                newChatID : null  
            }
        case 'FETCH_USER_CHATS_SUCCESS':
            return {...state, 
                items : {
                    ...action.chats
                },
                listeners : action.listeners,
                loading : false,
                error : null,
                newChatID : null 
            }
        case 'FETCH_USER_CHATS_ERROR':
            return { ...state, 
                loading : false,
                error : action.error 
            }
        case 'CREATE_CHAT_BEGIN':
            return {
                ...state,
                loading : true,
                error : false,
                newChatID : null 
            }
        
        case 'CREATE_CHAT_SUCCESS':
            
            return { ...state, 
                items :{
                    ...state.items,
                    [action.chat.id] : action.chat
                }, 
                error : false,
                loading : false ,
                newChatID : action.chat.id 
            }
        case 'CREATE_CHAT_ERROR':
                return { ...state, 
                    loading : false,
                    error : action.error 
                }
        case 'CREATE_CHAT_ERROR_EXISTING_CHAT':
            return { ...state, 
                loading : false,
                error : action.chat,
                newChatID : null  
            }
        case 'SET_ACTIVE_CHAT':
            return { ...state, 
                loading : false,
                error : false,
                newChatID : null 
            }
        case 'SET_ACTIVE_CHAT_ERROR':
            return { ...state, 
                loading : false,
                error : action.error,
                newChatID : null 
            }
        default:
            return state;
    }
};

let msgInitState = {
    loading: false,
    error: null,
    items : []
}
export const msgReducer = (state = msgInitState, action) => {

    switch (action.type) {
        case 'FETCH_SINGLE_CHAT_BEGIN':
            return {
                ...state,
                chatError : {},
                loading : true,
                error : false 
            }
        case 'FETCH_MORE_MSG_BEGIN':
            return {
                ...state,
                chatError : {},
                items : [
                    ...state.items
                ],
                loading : true,
                error : false 
            }
        case 'FETCH_SINGLE_CHAT_SUCCESS':
            return {...state, 
                items : [
                    ...action.messages
                ],
                loading : false,
                error : null  
            }
        case 'FETCH_MORE_MSG_SUCCESS':
            return {...state, 
                items : [
                    ...action.messages,
                    ...state.items
                ],
                loading : false,
                error : null  
            }
            

        case 'FETCH_SINGLE_CHAT_ERROR':
            return { ...state, 
                loading : false,
                error : action.error 
            }
        
        default:
            return state;
    }
};

