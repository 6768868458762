import getSubcategoriesFromCategories from "../../helpers/getSubcategoriesFromCategories"
// import * as geofire from "geofire-common";

export const  unSubListeners = (listeners) => {
    return (dispatch, getState, { getFirestore }) => {
        //console.log("removing listeners",listeners)
        if(listeners.length > 0){
            listeners.forEach(function(func){
                func(); // run your function
           });
        }
        
    dispatch({ type: "UNSUB_LISTENERS_SUCCESS" });
    };
};

export const getCategories = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({ type: "FETCH_CATEGORIES_BEGIN" });
        try {
            const firestore = getFirestore();
            let categories = []
            const categoriesSnap = await firestore.collection("categories").get();
            categoriesSnap.forEach(function (doc) {
                let cat = {...doc.data() }
                cat.id = doc.id
                categories.push(cat)
            });
            dispatch({ type: "FETCH_CATEGORIES_SUCCESS", categories });
        } catch (error) {
            dispatch({ type: "FETCH_CATEGORIES_ERROR", error });
        }
    };
};


export const getSubcategories = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({ type: "FETCH_SUB_CATEGORIES_BEGIN" });
        try {
            //get the categories first 
            const result = await dispatch(getCategories())
            const categories = getState().general.categories;
            const subcategories = getSubcategoriesFromCategories(categories)
            dispatch({ type: "FETCH_SUB_CATEGORIES_SUCCESS",subcategories });
        
        } catch (error) {
            dispatch({ type: "FETCH_SUB_CATEGORIES_ERROR", error });
        }
    };
};



// example to searcg user by location

// export const searchUsersByLocation = (location) => {
//     return (dispatch, getState, { getFirebase, getFirestore }) => {
//         const firebase = getFirebase();
//         const firestore = getFirestore();
//         dispatch({ type: "SEARCH_BY_LOCATION_BEGIN" });
//         //get users 50 miles from baltimore
//         const center = [39.2903848, -76.6121893]; //[39.2903848, -76.6121893] is baltimore
//         const radiusInM = 50 * 1000;
//         const bounds = geofire.geohashQueryBounds(center, radiusInM);
//         console.log(bounds)
//         const promises = [];
//         for (const b of bounds) {
//         const q = firestore.collection('users').where("isTeacher","==",true)
//             .orderBy('geohash')
//             .startAt(b[0])
//             .endAt(b[1]);

//             promises.push(q.get());
//         }
//         Promise.all(promises).then((snapshots) => {
//             const matchingDocs = [];
          
//             for (const snap of snapshots) {
//               for (const doc of snap.docs) {
//                 //console.log(doc.data())
//                 let data = doc.data()
//                 const lat = data.address.location.lat;
//                 const lng = data.address.location.lng;
          
//                 // We have to filter out a few false positives due to GeoHash
//                 // accuracy, but most will match
//                 const distanceInKm = geofire.distanceBetween([lat, lng], center);
//                 const distanceInM = distanceInKm * 1000;
//                 if (distanceInM <= radiusInM) {
//                     console.log("found",data)
//                     matchingDocs.push(data);
//                 }
//               }
//             }
          
//             return matchingDocs;
//         }).then((matchingDocs) => {
//             console.log(matchingDocs)
//             dispatch({ type: "SEARCH_BY_LOCATION_SUCCESS", matchingDocs });
//         });

      
//     };
// };


// //example to get user by skill

// export const searchUsersBySkill = (category,subcategory) => {
//     return async (dispatch, getState, { getFirebase, getFirestore }) => {
//         const firebase = getFirebase();
//         const firestore = getFirestore();
//         dispatch({ type: "SEARCH_BY_SKILLS_BEGIN" });
//         let searchSnap = await firestore.collection('users').where("isTeacher","==",true).where(`skills.${category}`,"!=",false).get()
//         if (subcategory){
//             searchSnap = await firestore.collection('users').where("isTeacher","==",true).where("isPublic", "==", true).where(`skills.${category}`,"array-contains",subcategory).get()
//         }
//         console.log("searchSnap.size", searchSnap)
        
//         searchSnap.forEach(function (doc) {
//             console.log("firebase data", doc.data())
//         }); 
//     };
// };
