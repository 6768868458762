import authReducer from './authReducer';
import classReducer from './classReducer';
import {chatReducer } from './chatReducer.js';
import userReducer  from './userReducer.js';
import {msgReducer} from './chatReducer.js';
import scheduleReducer from './scheduleReducer.js';
import generalReducer from './generalReducer';
import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';
import { reducer as formReducer } from 'redux-form';

const allReducers = combineReducers({
    auth: authReducer,
    classes: classReducer,
    firestore: firestoreReducer,
    firebase: firebaseReducer,
    form: formReducer,
    chats: chatReducer,
    messages : msgReducer,
    user : userReducer,
    schedules : scheduleReducer,
    general : generalReducer

});

const rootReducer = (state, action) => {
    //https://www.digitalocean.com/community/tutorials/redux-reset-state-redux
    if (action.type === 'RESET_APP') {
        //we extract the state we would want to keep after reset and set the state reference to only those reducers.
        //this way we reset everything else.
        const { firestore,firebase } = state;
        state = { firebase };
    }
    
    return allReducers(state, action);
}

export default rootReducer

// the key name will be the data property on the state object