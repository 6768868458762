const initState = {
    authError: null,
    authLoading: false,
    logout : false,
    presence: {},
    listeners: [],
};

const authReducer = (state = initState, action) => {
    switch (action.type) {
        case "LOGIN_ERROR":
            console.log("login error");
            return {
                ...state,
                authError: "Login failed",
                authLoading: false,
            };

        case "LOGIN_SUCCESS":
            console.log("login success");
            return {
                ...state,
                authError: null,
                uid: action.uid,
                authLoading: false,
                logout : false
            };

        case "SIGN_OUT_BEGIN":
            
            return {
                ...state,
                logout : true,
                authError: null,
                authLoading: true,
            };
        case "SIGN_OUT_SUCCESS":
            console.log("signout success");
            return {
                ...state,
                authLoading: false,
                logout : true
            };

        case "SIGNUP_SUCCESS":
            console.log("signup success",action);
            return {
                ...state,
                authError: null,
                uid: action.uid,
                authLoading: false,
                logout : false
            };

        case "SIGNUP_ERROR":
            console.log("signup error",action.err);
            return {
                ...state,
                authError: action.err,
                authLoading: false,
            };
        case "SET_PRESENCE":
            return {
                ...state,
                presence: {
                    ...state.presence, // copy the nested object (level 1)
                    [action.payload.id]: action.payload.status,
                },
                listeners: [...action.listeners],
            };
        case "PASSWORD_UPDATE_BEGIN":
            return {
                ...state,
                authLoading: true,
                success: false,
            };
        case "PASSWORD_UPDATE_SUCCESS":
            return {
                ...state,
                authLoading: false,
                success: true,
            };
        case "PASSWORD_UPDATE_ERROR":
            return {
                ...state,
                authLoading: false,
                authError: action.error.message,
                success: false,
            };
        case "PASSWORD_UPDATE_COMPLETE":
            return {
                ...state,
                authLoading: false,
                authError: null,
                success: false,
            };

        default:
            return state;
    }
};

export default authReducer;
