import _ from "lodash";

/*

This function takes the categories array and returns the sub categories sorted by the category name and then sub category name in the following format

INPUT:

[
    {
        name : "Music",
        subcategories : {
            cello: {name: 'Cello'},
            frenchhorn: {name: 'French Horn'},
            piano: {name: 'Piano'}
        }
    }
]

OUTPUT 
[
    {category: 'Music', categoryID : 'music', name: 'Cello', key:'cello'},
    {category: 'Music', categoryID : 'music', name: 'French Horn', key:'frenchhorn'},
    {category: 'Music', categoryID : 'music', name: 'Piano', key:'cello'}
]

*/

const getSubcategoriesFromCategories = (categories) => {
    if (categories.length === 0) return [];

    let items = [];
    //first sort the categories
    _.sortBy(categories, [
        function (o) {
            return o.name;
        },
    ]).map((cat) => {
        //then sort the sub categories
        _(cat.subcategories)
            .keys()
            .sort()
            .each(function (key) {
                let item = {};
                item.category = cat.name;
                item.categoryID = cat.id;
                item.name = cat.subcategories[key].name;
                item.key = key;
                items.push(item);
            });
    });
    return items;
};

export default getSubcategoriesFromCategories;
