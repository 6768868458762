import React, { Component } from "react";
import { withRouter } from "react-router";
import Badge from "@material-ui/core/Badge";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import { Route } from "react-router-dom";
import HomeIcon from "./icons/Home";
import MessagesIcon from "./icons/Messages";
import ScheduleIcon from "./icons/Schedule";
import Button from "@material-ui/core/Button";
import { withTheme, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { signOut } from "../../store/actions/authActions";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Avatar from "@material-ui/core/Avatar";
import Navbar from "./Navbar";
import { MAIN_BG_COLOR } from "../../constants";

function pxToRem(value) {
    return `${value / 16}rem`;
}

const drawerWidth = 120;

const styles = (theme) => ({
    root: {
        display: "flex",
    },
    drawer: {
        [theme.breakpoints.up("sm")]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    drawerInner : {
        display: "flex",
        flexDirection : "column",
        flexGrow: 1
    },
    menuButton: {
        marginRight: 20,
        [theme.breakpoints.up("sm")]: {
            display: "none",
        },
    },
    drawerPaper: {
        width: drawerWidth,
        background: "linear-gradient(5deg, rgb(53, 33, 80) 0%, rgb(37, 38, 72) 100%)", //#2F364C
    },
    button: {
        margin: 0,
        width: drawerWidth - 1,
        height: 100,
        boxShadow: "none",
        color: MAIN_BG_COLOR,
    },
    toptIcon: {
        textAlign: "center",
        maxHeight : 110
    },
});

class LeftNav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            mobileOpen: false,
        };
        this.handleClose = this.handleClose.bind(this);
    }

    handleDrawerToggle = () => {
        this.setState((state) => ({ mobileOpen: !state.mobileOpen }));
    };

    handleMenu = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose(e) {
        this.setState({ anchorEl: null });
        if (e.route) {
            if (e.route == "/logout") {
                this.props.signOut();
                return;
            }
            this.props.history.push(e.route);
        }
    }

    render() {
        const { classes, profile, privates, auth, theme, signOut } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);

        if (privates.chatInfo) {
            var chatIcon = (
                <Badge badgeContent={privates.chatInfo.totalUnseenChats} color="secondary" style={{ textIndent: "1px", lineHeight: 0 }}>
                    <MessagesIcon />
                </Badge>
            );
        } else {
            var chatIcon = <MessagesIcon />;
        }

        const drawerInner = (
            <div className={classes.drawerInner}>
                {/* <div className={classes.toolbar} /> */}
                <Grid item xs={12} className={classes.toptIcon}>
                    <img src="/img/mark10.png" loading="lazy" width="45" style={{ margin: "35px 0 25px 0" }} alt="logo" />
                </Grid>
                <Route
                    render={({ history }) => (
                        <Button
                            className={classes.button}
                            onClick={() => {
                                if(this.state.mobileOpen){
                                    this.handleDrawerToggle()
                                }
                                history.push("/");
                            }}
                        >
                            <Grid container>
                                <Grid item xs={12}>
                                    <HomeIcon />
                                </Grid>
                                <Grid item xs={12}>
                                    <div>Home</div>
                                </Grid>
                            </Grid>
                        </Button>
                    )}
                />
                <Route
                    render={({ history }) => (
                        <Button
                            className={classes.button}
                            onClick={() => {
                                if(this.state.mobileOpen){
                                    this.handleDrawerToggle()
                                }
                                history.push("/messages");
                            }}
                        >
                            <Grid container>
                                <Grid item xs={12}>
                                    {chatIcon}
                                </Grid>
                                <Grid item xs={12}>
                                    <div>Messages</div>
                                </Grid>
                            </Grid>
                        </Button>
                    )}
                />
                <Route
                    render={({ history }) => (
                        <Button
                            className={classes.button}
                            onClick={() => {
                                if(this.state.mobileOpen){
                                    this.handleDrawerToggle()
                                }
                                history.push("/schedule");
                            }}
                        >
                            <Grid container>
                                <Grid item xs={12}>
                                    <ScheduleIcon />
                                </Grid>
                                <Grid item xs={12}>
                                    <div>Schedule</div>
                                </Grid>
                            </Grid>
                        </Button>
                    )}
                />

                <IconButton
                    aria-owns={open ? "menu-appbar" : undefined}
                    aria-haspopup="true"
                    onClick={this.handleMenu}
                    color="inherit"
                    className={classes.button}
                    style={{ marginTop: "auto" }}
                >
                    {this.props.profile.avatarURL && (
                        <Avatar alt={this.props.profile.firstName} src={this.props.profile.avatarURL} style={{ width: 55, height: 55 }} />
                    )}
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    open={open}
                    onClose={this.handleClose}
                >
                    <MenuItem
                        onClick={() => {
                            if(this.state.mobileOpen){
                                this.handleDrawerToggle()
                            }
                            this.handleClose({ route: "/settings/#profile" });
                        }}
                    >
                        Settings
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            if(this.state.mobileOpen){
                                this.handleDrawerToggle()
                            }
                            this.handleClose({ route: "/logout" });
                        }}
                    >
                        Logout
                    </MenuItem>
                </Menu>
            </div>
        );

        let drawer = (
            <React.Fragment>
                <Navbar handleDrawerToggle={this.handleDrawerToggle} />
                <nav className={classes.drawer}>
                    <Hidden smUp implementation="css">
                        <Drawer
                            container={this.props.container}
                            variant="temporary"
                            anchor={theme.direction === "rtl" ? "right" : "left"}
                            open={this.state.mobileOpen}
                            onClose={this.handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                        >
                            {drawerInner}
                        </Drawer>
                    </Hidden>
                    <Hidden xsDown implementation="css">
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            variant="permanent"
                            open
                        >
                            {drawerInner}
                        </Drawer>
                    </Hidden>
                </nav>
            </React.Fragment>
        );

        if (!auth.uid) {
            //no nav if not logged in
            drawer = <div />;
        }
        return drawer;
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut()),
    };
};

export default withRouter(withTheme(withStyles(styles)(connect(null, mapDispatchToProps)(LeftNav))));
