import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from './store/reducers/rootReducer'
import { Provider , useSelector} from 'react-redux'
import thunk from 'redux-thunk'
import fbConfig from './config/fbConfig'
import disableReactDevTools from "./helpers/disableReactDevTools";
import { ReactReduxFirebaseProvider,isLoaded ,getFirebase} from 'react-redux-firebase'
import { createFirestoreInstance,getFirestore,reduxFirestore } from 'redux-firestore'
const initialState = {}

//remove react dev tools in PROD
if (process.env.NODE_ENV === "production") disableReactDevTools();

//console.log("process.env.NODE_ENV",process.env.NODE_ENV)

function AuthIsLoaded({ children }) {
  const auth = useSelector(state => state.firebase.auth)
  if (!isLoaded(auth)) return <div className='gradient'>splash screen...</div>;
  return children
}


const store = createStore(rootReducer,
  initialState,
  compose(
    applyMiddleware(thunk.withExtraArgument({getFirebase,getFirestore})),
    reduxFirestore(fbConfig)
    )
);

const rrfConfig = {userProfile: 'users', useFirestoreForProfile: true, attachAuthIsReady: true, profileParamsToPopulate : true }
const rrfProps = {
  firebase : fbConfig,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance 
}


  ReactDOM.render(
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
      <AuthIsLoaded>
        <App />
      </AuthIsLoaded>
      </ReactReduxFirebaseProvider>
    </Provider>, 
    document.getElementById('root')
  );
  //registerServiceWorker();


