

export const profileUpdate = (user) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        const firestore = getFirestore();
        const uid = getState().firebase.auth.uid;
        let updatedUser = { ...user };
        delete updatedUser.isEmpty;
        delete updatedUser.isLoaded;
        if (updatedUser.address){
            if (updatedUser.address.formatted_address === ""){
                updatedUser.address = null
            }
        }
        
        dispatch({ type: "PROFILE_UPDATE_BEGIN" });
        firestore
            .collection("users")
            .doc(uid)
            .set({
                ...updatedUser,
            })
            .then(() => {
                dispatch({ type: "PROFILE_UPDATE_SUCCESS" });
            })
            .catch((err) => {
                dispatch({ type: "PROFILE_UPDATE_ERROR", err });
                console.log("err",err)
            });
    };
};

export const uploadAvatar = (blob) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        const firebase = getFirebase();
        const uid = getState().firebase.auth.uid;
        const fileMetadata = {
            customMetadata: {
                owner: uid,
            },
        };
        dispatch({ type: "PROFILE_UPDATE_BEGIN" });

        try {
            var uploadedFile = await firebase.uploadFile(`/avatars`, blob, null, {
                metadata: fileMetadata,
            });
            var publicUrl = await uploadedFile.uploadTaskSnapshot.ref.getDownloadURL();

            firestore
                .collection("users")
                .doc(uid)
                .set({ avatarURL: publicUrl }, { merge: true })
                .then(function (docRef) {
                    dispatch({ type: "PROFILE_UPDATE_SUCCESS" });
                })
                .catch(function (error) {
                    dispatch({ type: "PROFILE_UPDATE_ERROR", error });
                });
        } catch (error) {
            dispatch({ type: "PROFILE_UPDATE_ERROR", error });
        }
    };
};

export const userPrivates = () => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        //wait a little for firebase to do its thing
       
        setTimeout(() => {
            const firebase = getFirebase();
            let listeners = [];
            var uid = getState().firebase.auth.uid;
            
            if (uid) {
                const unsub = firebase.firestore().collection('users').doc(uid).collection("private")
                    .onSnapshot(function(querySnapshot) {
                        let privates = {}
                        querySnapshot.forEach(function(doc) {
                            privates[doc.id] = doc.data()
                        });
                        
                        listeners.push(unsub);
                        dispatch({ type: 'SET_USER_PRIVATES', privates: privates, listeners: listeners   });
                    });
            }
        }, 500);


    };
}

export const userUnfulfilledPackagePurchases = () => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        //wait a little for firebase to do its thing

        setTimeout(async () => {
            const firebase = getFirebase();
            var uid = getState().firebase.auth.uid;
            if (uid) {
                try {
                    dispatch({ type: "FETCH_USER_PENDING_PACKAGE_PURCHASE_BEGIN" });
                    const pendingPurchasesSnap = await firebase
                        .firestore()
                        .collectionGroup("package_purchases")
                        .where("fulfilled", "==", false)
                        .where("metadata.uid", "==", uid)
                        .get();

                    var pending;
                    if (pendingPurchasesSnap.empty){
                        pending = false
                    }else{
                        pending = {}
                        pendingPurchasesSnap.forEach(function (doc) {
                            pending[doc.id] = doc.data();
                        });
                    }
                    
                    dispatch({ type: "FETCH_USER_PENDING_PACKAGE_PURCHASE_SUCCESS", pending });
                } catch (error) {
                    dispatch({ type: "FETCH_USER_PENDING_PACKAGE_PURCHASE_ERROR", error });
                }
            }
        }, 500);
    };
};

export const upsertAvailability = (data) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        const firestore = getFirestore();
        var uid = getState().firebase.auth.uid;

        dispatch({ type: "UPSERT_AVAILABILITY_BEGIN" });
        firestore
            .collection("users")
            .doc(uid)
            .collection("private")
            .doc("availability")
            .set(data)
            .then(() => {
                console.log("saved",data,uid)
                dispatch({ type: "UPSERT_AVAILABILITY_SUCCESS" });
            })
            .catch((err) => {
                dispatch({ type: "UPSERT_AVAILABILITY_ERROR", err });
            });
    };
};
export const getUserKnownContacts = (contatcs) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({ type: "FETCH_USER_KNOWN_CONTACTS_BEGIN" });
        const firebase = getFirebase();
        const firestore = getFirestore();
        var uid = getState().firebase.auth.uid;
        var users = {};
        const usersSnap = await firestore.collection("users").where(firestore.FieldPath.documentId(), "in", contatcs).get();
        usersSnap.forEach(function (doc) {
            users[doc.id] = doc.data();
        });
        dispatch({ type: "FETCH_USER_KNOWN_CONTACTS_SUCCESS", users });
    };
};

export const createUserPlanCheckoutSession = (data) => {
    return (dispatch) => {
        dispatch({ type: "CREATE_USER_PLAN_CHECKOUT_SESSION_BEGIN" });
        //https://us-east4-camrata-app.cloudfunctions.net/fetchCheckoutSession
        fetch("https://us-east4-camrata-app.cloudfunctions.net/fetchCheckoutSession", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then(function (response) {
                if (response.status !== 200) {
                    //console.log('Looks like there was a problem. Status Code: ' + response.status);
                    dispatch({ type: "CREATE_USER_PLAN_CHECKOUT_SESSION_ERROR" });
                    return;
                }
                response.json().then(function (data) {
                    //console.log(data);
                    dispatch({ type: "CREATE_USER_PLAN_CHECKOUT_SESSION_SUCCESS", data });
                });
            })
            .catch(function (err) {
                //console.log('Fetch Error :-S', err);
                dispatch({ type: "CREATE_USER_PLAN_CHECKOUT_SESSION_ERROR" });
            });
    };
};

export const createBillingPortalSession = () => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        dispatch({ type: "CREATE_BILLING_PORTAL_SESSION_BEGIN" });

        var billingPortal = firebase.functions().httpsCallable("fetchBillingPortalSession");
        billingPortal()
            .then(function (result) {
                dispatch({ type: "CREATE_BILLING_PORTAL_SESSION_SUCCESS", result });
            })
            .catch(function (error) {
                // Getting the Error details.
                var code = error.code;
                var message = error.message;
                var details = error.details;
                //console.log(error);
                dispatch({ type: "CREATE_BILLING_PORTAL_SESSION_ERROR" });
            });
    };
};

export const connectStripeAccount = (data) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        dispatch({ type: "CONNECT_STRIPE_BEGIN" });

        var connectStripe = firebase.functions().httpsCallable("connectStripeAccount");
        connectStripe({ authorization_code: data.authorization_code })
            .then(function (result) {
                //console.log("result", result);
                dispatch({ type: "CREATE_USER_PLAN_CHECKOUT_SESSION_SUCCESS", result });
            })
            .catch(function (error) {
                // Getting the Error details.
                var code = error.code;
                var message = error.message;
                var details = error.details;
                //console.log(error);
                dispatch({ type: "CREATE_USER_PLAN_CHECKOUT_SESSION_ERROR",error });
                // ...
            });
    };
};

export const getCheckoutSessionByID = (data) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        dispatch({ type: "FETCH_CHECKOUT_SESSION_BY_ID_BEGIN" });
        var ta = firebase.functions().httpsCallable("getCheckoutSessionByID");
        ta(data)
            .then(function (result) {
                dispatch({ type: "FETCH_CHECKOUT_SESSION_BY_ID_SUCCESS", result });
            })
            .catch(function (error) {
                // Getting the Error details.
                var code = error.code;
                var message = error.message;
                var details = error.details;
                //console.log(error);
                dispatch({ type: "FETCH_CHECKOUT_SESSION_BY_ID_ERROR", error });
            });
    };
};

export const submitVerification = () => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        const firestore = getFirestore();
        var uid = getState().firebase.auth.uid;
        let data = {
            uid : uid,
            status : "PENDING"
        }
        dispatch({ type: "PROFILE_SUBMIT_VERIFICATION_BEGIN" });
        firestore
            .collection("verifications")
            .doc(uid)
            .set(data)
            .then((doc) => {
                
                dispatch({ type: "PROFILE_SUBMIT_VERIFICATION_SUCCESS", verification: data });
            })
            .catch((err) => {
                dispatch({ type: "PROFILE_SUBMIT_VERIFICATION_ERROR", err });
            });
    };
};

export const fetchVerification = () => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        const firestore = getFirestore();
        var uid = getState().firebase.auth.uid;
        dispatch({ type: "PROFILE_FETCH_VERIFICATION_BEGIN" });
        firestore
            .collection("verifications")
            .doc(uid)
            .get()
            .then((snap) => {
                let doc = {};
                if (snap.exists){
                    doc = snap.data()
                }
                dispatch({ type: "PROFILE_FETCH_VERIFICATION_SUCCESS", verification: doc});
            })
            .catch((err) => {
                dispatch({ type: "PROFILE_FETCH_VERIFICATION_ERROR", err });
            });
    };
};

