import React, { Component, Suspense, lazy } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { BrowserRouter, Switch, Route } from "react-router-dom";
//import Dashboard from "./components/dashboard/Dashboard";
//import ClassDetails from "./components/classes/ClassDetails";
//import ManageClass from "./components/classes/manage/manageClass";
//import SignIn from "./components/auth/SignIn";
//import SignUp from "./components/auth/SignUp";
//import Settings from "./components/user/Settings";
//import Messages from "./components/communications/messages";
//import FullCalendar from "./components/schedule/FullCalendar";
//import AvailabilityBuilder from "./components/schedule/AvailabilityBuilder";
//import ClassPackages from "./components/classes/signup/packages"
//import BookNow from "./components/classes/signup/BookNow";
//import Chats from "./components/communications/chats";
import LeftNav from "./components/layout/LeftNav";
import { MuiThemeProvider, createTheme, responsiveFontSizes } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { compose } from "redux";
import { userPresence, signOut } from "./store/actions/authActions";
import { userPrivates } from "./store/actions/userActions";
import {unSubListeners} from "./store/actions/generalActions"
//import VideoChat from "./components/meet/twilio/VideoChat";
import { PRIMARY_TEXT_COLOR, MAIN_BG_COLOR } from "./constants";
import LinearProgress from "@material-ui/core/LinearProgress";
//import TeacherFirstExperience from "./components/user/TeacherFirstExperience";
//import NotFound from "./components/layout/NotFound";

//code split
const ClassDetails = lazy(() => import("./components/classes/ClassDetails"));
const ManageClass = lazy(() => import("./components/classes/manage/manageClass"));
const Settings = lazy(() => import("./components/user/Settings"));
const SignIn = lazy(() => import("./components/auth/SignIn"));
const SignUp = lazy(() => import("./components/auth/SignUp"));
const Dashboard = lazy(() => import("./components/dashboard/Dashboard"));
const Messages = lazy(() => import("./components/communications/messages"));
const FullCalendar = lazy(() => import("./components/schedule/FullCalendar"));
const AvailabilityBuilder = lazy(() => import("./components/schedule/AvailabilityBuilder"));
const ClassPackages = lazy(() => import("./components/classes/signup/packages"));
const BookNow = lazy(() => import("./components/classes/signup/BookNow"));
const Chats = lazy(() => import("./components/communications/chats"));
const VideoChat = lazy(() => import("./components/meet/twilio/VideoChat"));
const TeacherFirstExperience = lazy(() => import("./components/user/TeacherFirstExperience"));
const NotFound = lazy(() => import("./components/layout/NotFound"));



function pxToRem(value) {
    return `${value / 16}rem`;
}

const breakpoints = createBreakpoints({});

let c_Theme = createTheme({
    breakpoints,
    palette: {
        background: {
            default: MAIN_BG_COLOR,
        },
        primary: {
            // light: will be calculated from palette.primary.main,
            main: "#9472F6",
            //dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
            contrastText: "#fff",
        },
        secondary: {
            //light: '#0066ff',
            main: "#00bcd4",
            // dark: will be calculated from palette.secondary.main,
            contrastText: "#fff",
        },
        text: {
            primary: PRIMARY_TEXT_COLOR,
        },
        // error: will use the default color
    },
    typography: {
        useNextVariants: true,
        fontFamily: ["Open Sans", "sans-serif"].join(","),
        fontDisplay: "swap",
        body1: {
            fontSize: 18,
        },
        body2: {
            fontSize: 14,
        },
        button: {
            fontFamily: ["Open Sans", "sans-serif"].join(","),
            fontWeight: 500,
            fontWeight: "bold",
        },
        subtitle2: {
            fontFamily: ["Paytone One", "sans-serif"].join(","),
            color: PRIMARY_TEXT_COLOR,
        },
        h2: {
            fontFamily: ["Paytone One", "sans-serif"].join(","),
            color: PRIMARY_TEXT_COLOR,
        },
        h3: {
            fontFamily: ["Paytone One", "sans-serif"].join(","),
            color: PRIMARY_TEXT_COLOR,
        },
        h4: {
            fontFamily: ["Paytone One", "sans-serif"].join(","),
            color: PRIMARY_TEXT_COLOR,
        },
        h6: {
            fontFamily: ["Open Sans", "Paytone One", "sans-serif"].join(","),
            color: PRIMARY_TEXT_COLOR,
            fontWeight: "bold",
        },
    },
});

c_Theme = responsiveFontSizes(c_Theme);

const styles = (theme) => ({
    root: {
        [theme.breakpoints.up("sm")]: {
            display: "flex",
        },
        flexGrow: 1,
    },
    content: {
        flexGrow: 1,
        margin: "0 auto",
        height: `calc(100% - 56px)`,
        width: `100%`,
        [theme.breakpoints.up("sm")]: {
            height: `100%`,
            width: "calc(100% - 120px)",
        },
        //height: `100%`,
        // overflowX: "hidden",
        // overflowY: "auto"
    },
    toolbar: {
        minHeight: 0,
        [theme.breakpoints.down("xs")]: {
            minHeight: 56,
        },
    },
    pad: {
        height: 56,
        [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
            height: 48,
        },
        [theme.breakpoints.up("sm")]: {
            height: 64,
        },
    },
});

class App extends Component {
    constructor(props) {
        super(props);
    }

    handleDrawerToggle = () => {
        this.setState((state) => ({ mobileOpen: !state.mobileOpen }));
    };
    componentDidMount() {
        if (this.props.auth.uid) {
            this.props.userPrivates();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.privates !== prevProps.privates) {
            //IMPORTANT
            //need to make sure privates are set before we call the user presence because inside of userPresence we rely on the privates state to be set
            //when upgrading this component to use hooks, make sure the privates are set in state before calling this function
            this.props.userPresence();
        }
    }

    componentWillUnmount(){
        this.props.unSubListeners(this.props.userListeners)
    }

    render() {
        const { classes, theme, auth, presence, profile, privates, authLoading } = this.props;

        return (
            <React.Fragment>
                <MuiThemeProvider theme={c_Theme}>
                    <CssBaseline />
                    <BrowserRouter>
                        <Suspense fallback={<LinearProgress />}>
                            <div className={"App " + classes.root}>
                                {authLoading && <LinearProgress />}

                                <LeftNav profile={profile} privates={privates} auth={auth} />
                                <div className={classes.pad} />
                                <div className={classes.content}>
                                    <Switch>
                                        <Route exact path="/" component={Dashboard} />
                                        <Route path="/classes/:id/manage" component={ManageClass} />
                                        <Route path="/classes/:id/booknow/:sessionid" exact component={BookNow} />
                                        <Route path="/classes/:id/packages" exact component={ClassPackages} />
                                        <Route path="/classes/:id/packages/:packageID" exact component={ClassPackages} />
                                        <Route path="/classes/:id" component={ClassDetails} />
                                        <Route path="/signin" component={SignIn} />
                                        <Route path="/signup" component={SignUp} />
                                        <Route path="/settings" component={Settings} />
                                        <Route exact path="/messages/:id" render={(props) => <Messages {...props} presence={presence} />} />
                                        <Route exact path="/messages" render={(props) => <Chats {...props} presence={presence} />} />
                                        <Route exact path="/schedule" component={FullCalendar} />
                                        <Route path="/schedule/availability" component={AvailabilityBuilder} />
                                        <Route exact path="/meet/:id" render={(props) => <VideoChat {...props} presence={presence} />} />
                                        <Route
                                            exact
                                            path="/FTUE"
                                            render={(props) => <TeacherFirstExperience {...props} presence={presence} />}
                                        />
                                        <Route component={NotFound} />
                                    </Switch>
                                </div>
                            </div>
                        </Suspense>
                    </BrowserRouter>
                </MuiThemeProvider>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    //console.log(state)
    return {
        auth: state.firebase.auth,
        authLoading: state.auth.authLoading,
        presence: state.auth.presence,
        profile: state.firebase.profile,
        privates: state.user.privates,
        userListeners: state.user.listeners
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        userPresence: () => dispatch(userPresence()),
        userPrivates: () => dispatch(userPrivates()),
        unSubListeners : (listeners) => dispatch(unSubListeners(listeners)),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles, { withTheme: true }))(App);
