import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

const ScheduleIcon = (props) => {
  return (
	<SvgIcon {...props}>
              <g id="Outline_Icons_1_">
                <g id="Outline_Icons">
                  <g>
                    <path d="M23.5,2H20V0.5C20,0.224,19.776,0,19.5,0h-3C16.224,0,16,0.224,16,0.5V2H8V0.5C8,0.224,7.776,0,7.5,0h-3
                      C4.224,0,4,0.224,4,0.5V2H0.5C0.224,2,0,2.224,0,2.5v21C0,23.776,0.224,24,0.5,24h23c0.276,0,0.5-0.224,0.5-0.5v-21
                      C24,2.224,23.776,2,23.5,2z M17,1h2v3h-2V1z M5,1h2v3H5V1z M4,3v1.5C4,4.776,4.224,5,4.5,5h3C7.776,5,8,4.776,8,4.5V3h8v1.5
                      C16,4.776,16.224,5,16.5,5h3C19.776,5,20,4.776,20,4.5V3h3v4H1V3H4z M1,23V8h22v15H1z"/>
                    <path d="M21.5,12c0.276,0,0.5-0.224,0.5-0.5S21.776,11,21.5,11H17V9.5C17,9.224,16.776,9,16.5,9S16,9.224,16,9.5V11h-4V9.5
                      C12,9.224,11.776,9,11.5,9S11,9.224,11,9.5V11H7V9.5C7,9.224,6.776,9,6.5,9S6,9.224,6,9.5V11H2.5C2.224,11,2,11.224,2,11.5
                      S2.224,12,2.5,12H6v3H2.5C2.224,15,2,15.224,2,15.5S2.224,16,2.5,16H6v3H2.5C2.224,19,2,19.224,2,19.5S2.224,20,2.5,20H6v1.5
                      C6,21.776,6.224,22,6.5,22S7,21.776,7,21.5V20h4v1.5c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5V20h4v1.5
                      c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5V20h4.5c0.276,0,0.5-0.224,0.5-0.5S21.776,19,21.5,19H17v-3h4.5
                      c0.276,0,0.5-0.224,0.5-0.5S21.776,15,21.5,15H17v-3H21.5z M7,12h4v3H7V12z M7,19v-3h4v3H7z M16,19h-4v-3h4V19z M16,15h-4v-3h4
                      V15z"/>
                  </g>
                </g>
                <g id="New_icons_1_">
                </g>
              </g>
              <g id="Invisible_Shape">
                <rect fill="none" width="24" height="24"/>
              </g>
            </SvgIcon>
    )
}



export default ScheduleIcon


