const initState = {
    contacts: {},
    loading: false,
    error: null,
    stripeSession: null,
    billingPortalSession: null,
    stripeConnected: null,
    avatarUploadComplete: false,
    userPurchaseCheckOutSession: null,
    unfulfilledPackagePurchases: null,
    listeners: [],
    privates : {},
    verification : {}
};

const userReducer = (state = initState, action) => {
    switch (action.type) {
        case "PROFILE_UPDATE_BEGIN":
        case "FETCH_USER_KNOWN_CONTACTS_BEGIN":
        case "CREATE_USER_PLAN_CHECKOUT_SESSION_BEGIN":
        case "CONNECT_STRIPE_BEGIN":
        case "CREATE_BILLING_PORTAL_SESSION_BEGIN":
        case "UPSERT_AVAILABILITY_BEGIN":
        case "FETCH_CHECKOUT_SESSION_BY_ID_BEGIN":
        case "FETCH_USER_PENDING_PACKAGE_PURCHASE_BEGIN":
        case "PROFILE_SUBMIT_VERIFICATION_BEGIN":  
        case "PROFILE_FETCH_VERIFICATION_BEGIN":  
            return {
                ...state,
                loading: true,
                error: null,
                avatarUploadComplete: false,
            };
        case 'SET_USER_PRIVATES':
                return {
                    ...state,
                    privates : action.privates,
                    listeners : action.listeners
                }
        case "PROFILE_UPDATE_SUCCESS":
            console.log("profile update success");
            return {
                ...state,
                loading: false,
                error: null,
                avatarUploadComplete: true,
            };
        case "PROFILE_UPDATE_ERROR":
            
            return {
                ...state,
                loading: false,
                error: "There was a problem saving your profile.",
                avatarUploadComplete: false,
            };

        case "FETCH_USER_KNOWN_CONTACTS_SUCCESS":
            return Object.assign({}, state, {
                contacts: action.users,
                loading: false,
            });

        case "FETCH_USER_KNOWN_CONTACTS_ERROR":
            return Object.assign({}, state, {
                contacts: action.users,
                loading: false,
                error: action.error,
            });

        case "CREATE_USER_PLAN_CHECKOUT_SESSION_SUCCESS":
            return Object.assign({}, state, {
                stripeSession: action.data,
                loading: false,
            });
        case "CREATE_USER_PLAN_CHECKOUT_SESSION_ERROR":
            return {
                ...state,
                loading: false,
                error: "There was a problem, please try again later.",
            };

        case "CONNECT_STRIPE_SUCCESS":
            return Object.assign({}, state, {
                stripeConnected: action.data,
                loading: false,
            });
        case "CONNECT_STRIPE_ERROR":
            return {
                ...state,
                loading: false,
                error: "There was a problem conecting your Stripe account, please try again later.",
            };

        case "CREATE_BILLING_PORTAL_SESSION_SUCCESS":
            return Object.assign({}, state, {
                billingPortalSession: action.result.data,
                loading: false,
            });
        case "CREATE_BILLING_PORTAL_SESSION_ERROR":
            return {
                ...state,
                loading: false,
                error: "There was a problem, please try again later.",
            };

        case "UPSERT_AVAILABILITY_SUCCESS":
            return {
                ...state,
                loading: false,
                error: null,
            };
        case "UPSERT_AVAILABILITY_ERROR":
            return {
                ...state,
                loading: false,
                error: "There was a problem, please try again later.",
            };

        case "FETCH_CHECKOUT_SESSION_BY_ID_SUCCESS":
            return Object.assign({}, state, {
                userPurchaseCheckOutSession: action.result.data,
                loading: false,
            });
        case "FETCH_CHECKOUT_SESSION_BY_ID_ERROR":
            return Object.assign({}, state, {
                error: action.error.message,
                loading: false,
            });
        case "FETCH_USER_PENDING_PACKAGE_PURCHASE_SUCCESS":
            return Object.assign({}, state, {
                unfulfilledPackagePurchases: action.pending,
                loading: false,
            });
        case "PROFILE_SUBMIT_VERIFICATION_SUCCESS":
        case "PROFILE_FETCH_VERIFICATION_SUCCESS":
            return Object.assign({}, state, {
                verification: action.verification,
                loading: false,
            });

        default:
            return state;
    }
};

export default userReducer;
