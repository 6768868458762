import React, { Component }  from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { signOut } from '../../store/actions/authActions'
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';


class SignedInLinks extends Component {
  
  constructor(props){
    super(props);
    this.state = {
      anchorEl: null,
    };
    this.handleClose = this.handleClose.bind(this)
    
  }

  
  
  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose(e){
    this.setState({ anchorEl: null });
    if (e.route){
      this.props.history.push(e.route);
    }  
  };


  render(){
    
    const {anchorEl} = this.state;
    const open = Boolean(anchorEl);
    

  return (
    
    <React.Fragment>
     <div style={{marginLeft: 'auto'}}>
       <IconButton
          aria-owns={open ? 'menu-appbar' : undefined}
          aria-haspopup="true"
          onClick={this.handleMenu}
          color="inherit"
        >
        {this.props.profile.avatarURL && <Avatar alt={this.props.profile.firstName} src={this.props.profile.avatarURL} />}
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={this.handleClose}
        >
        
          <MenuItem onClick={() => this.handleClose({route : '/create'})}>New Project</MenuItem>
          <MenuItem onClick={() => this.handleClose({route : '/settings/#profile'})}>Profile</MenuItem>
          <MenuItem onClick={this.props.signOut}>Logout</MenuItem>
        </Menu>
      </div>
    </React.Fragment>
);
}
}


const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut())
  }
}

export default withRouter(connect(null, mapDispatchToProps)(SignedInLinks))