import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

const HomeIcon = (props) => {
  return (
	<SvgIcon  {...props}>
		<g id="Outline_Icons">
			<g>
				<path d="M20.5,13.5c-0.276,0-0.5,0.224-0.5,0.5v9h-5v-6.5c0-0.276-0.224-0.5-0.5-0.5h-5C9.224,16,9,16.224,9,16.5V23H4v-9.5
				C4,13.224,3.776,13,3.5,13S3,13.224,3,13.5v10C3,23.777,3.224,24,3.5,24h6c0.276,0,0.5-0.224,0.5-0.5V17h4v6.5
				c0,0.276,0.224,0.5,0.5,0.5h6c0.276,0,0.5-0.224,0.5-0.5V14C21,13.724,20.776,13.5,20.5,13.5z"/>
				<path d="M23.854,12.647l-11.5-11.5c-0.195-0.195-0.512-0.195-0.707,0l-11.5,11.5c-0.195,0.195-0.195,0.512,0,0.707
				s0.512,0.195,0.707,0L12,2.208l11.146,11.146c0.098,0.098,0.226,0.146,0.354,0.146s0.256-0.049,0.354-0.146
				C24.049,13.159,24.049,12.842,23.854,12.647z"/>
				<path d="M16,3h3v3c0,0.276,0.224,0.5,0.5,0.5S20,6.277,20,6V2.5C20,2.224,19.776,2,19.5,2H16c-0.276,0-0.5,0.224-0.5,0.5
				S15.724,3,16,3z"/>
			</g>
			</g>
			<g id="invisible_shape">
			<rect fill="none" width="24" height="24"/>
			</g>
		</SvgIcon>
    )
}



export default HomeIcon


