export const PRIMARY_COLOR = "#9472F6";
export const GREEN_CHECK_COLOR = "#57d68f";
export const RED_WARNING_COLOR = "#F6636B";
export const MAIN_BG_COLOR = "#fafafa";
export const AVATAR_URL = "https://firebasestorage.googleapis.com/v0/b/camrata-app.appspot.com/o/avatars%2F";
export const PRIMARY_TEXT_COLOR = "#4c555a"
export const STRONG_PASSWORD_REGEX = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
export const STRIPE_PUBLISHABLE_KEY = "pk_test_51HJr5dHQQW64uX6SEasYmOMM3ffH91juYLtL68HHDsbL4qSpGQsftrWXHzyOtstCfoAdMsd69m9joY8Bo8UB7vr1009pKnM54c";
export const STRIPE_CONNECT_OAUTH_URL = "https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_IBGwW2tjG8DYkbYDEHcoWUY1ZCPVWPsm&scope=read_write";
export const PAID_PLAN_PRICE_ID = "price_1HZp5sHQQW64uX6SuoMqNby8" //"price_1HfyAKHQQW64uX6SeijiMReW";
export const FREE_PLAN_MAX_LESSON_TIME_SECONDS = 32400; //32400 = 9hours
export const DEFAULT_CURRENCY = "usd";
export const BIO_CHARACTER_LIMIT = 3500;
export const AWARDS_CHARACTER_LIMIT = 1000;
export const MAX_NEW_SKILLS = 10;
export const DEFAULT_STUDIO_IMAGE_URL = "https://firebasestorage.googleapis.com/v0/b/camrata-app.appspot.com/o/class-icons%2Ftreble-clef.png?alt=media&token=fd10a422-5602-4c8d-ad0a-39fa3eff2d14"