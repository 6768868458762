const initState = {
    loading: false,
    error: null,
    categories: [],
    subcategories: []
    
};

const generalReducer = (state = initState, action) => {
    switch (action.type) {
        case "FETCH_CATEGORIES_BEGIN":
            return {
                ...state,
                loading: true,
                error: false,
            };
        case "FETCH_CATEGORIES_SUCCESS":
            //console.log("action.categories",action.categories)
            return Object.assign({}, state, {
                categories: action.categories,
                loading: false
            });
        case "FETCH_SUB_CATEGORIES_SUCCESS":
            return Object.assign({}, state, {
                subcategories: action.subcategories,
                loading: false
            });

        case "FETCH_CATEGORIES_ERROR":
        case "FETCH_SUB_CATEGORIES_ERROR":  
            return { ...state, loading: false, error: action.error };
        
        default:
            return state;
    }
};

export default generalReducer;
