import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

const MessagesIcon = (props) => {
  return (
	<SvgIcon {...props}>
		<g id="Outline_Icons_1_">
		<g id="Outline_Icons">
			<g>
			<path d="M12,1C5.383,1,0,5.434,0,10.883c0,2.574,1.177,4.986,3.325,6.831l-2.41,4.812c-0.095,0.189-0.06,0.42,0.089,0.572
				c0.096,0.099,0.226,0.151,0.358,0.151c0.071,0,0.144-0.016,0.211-0.047l6.437-2.996c1.28,0.371,2.622,0.56,3.99,0.56
				c6.617,0,12-4.434,12-9.883S18.617,1,12,1z M12,19.766c-1.336,0-2.642-0.192-3.883-0.571c-0.118-0.036-0.246-0.027-0.357,0.025
				L2.452,21.69l1.946-3.884c0.105-0.211,0.049-0.467-0.135-0.614C2.159,15.51,1,13.27,1,10.883C1,5.984,5.935,2,12,2
				s11,3.984,11,8.883S18.065,19.766,12,19.766z"/>
			<path d="M6,9c-1.103,0-2,0.897-2,2s0.897,2,2,2s2-0.897,2-2S7.103,9,6,9z M6,12c-0.551,0-1-0.448-1-1s0.449-1,1-1s1,0.448,1,1
				S6.551,12,6,12z"/>
			<path d="M12,9c-1.103,0-2,0.897-2,2s0.897,2,2,2s2-0.897,2-2S13.103,9,12,9z M12,12c-0.551,0-1-0.448-1-1s0.449-1,1-1
				s1,0.448,1,1S12.551,12,12,12z"/>
			<path d="M18,9c-1.103,0-2,0.897-2,2s0.897,2,2,2s2-0.897,2-2S19.103,9,18,9z M18,12c-0.551,0-1-0.448-1-1s0.449-1,1-1
				s1,0.448,1,1S18.551,12,18,12z"/>
			</g>
		</g>
		<g id="New_icons_1_">
		</g>
		</g>
		<g id="Invisible_Shape">
		<rect fill="none" width="24" height="24"/>
		</g>
	</SvgIcon>
    )
}



export default MessagesIcon


