const initState = {
    loading: false,
    teacher_available_weekdays_loading : false,
    teacher_open_schedule_loading : false,
    error: null,
    currentClass: {
        teacher_available_weekdays : null,
        teacher_open_schedule : null,
        purchaseDatePrefCompleted : null 
    },
    userClasses: {},
    bookLessonStripeSession : null
};

const classReducer = (state = initState, action) => {
    switch (action.type) {
        case "CREATE_CLASS_SUCCESS":
            //console.log("create project success");
            return state;

        case "CREATE_CLASS_ERROR":
            //console.log("create project error");
            return state;

        case "FETCH_USER_CLASSES_BEGIN":
        case "FETCH_CLASS_STUDENTS_BEGIN":
        case "FETCH_SINGLE_CLASS_BEGIN":
        case "FETCH_CLASS_REQUIREMENTS_BEGIN":
        case "SAVE_REQUIREMENT_BEGIN":
        case "FETCH_CLASS_RESOURCES_BEGIN":
        case "SAVE_RESOURCES_BEGIN":
        case "FETCH_CLASS_PACKAGES_BEGIN":
        case "SAVE_PACKAGE_BEGIN": 
        case "CREATE_BUY_PACKAGE_CHECKOUT_SESSION_BEGIN":  
        case "GET_CONNECTED_ACCOUNT_ID_BEGIN": 
        case "TO_FULLFIL_BEGIN":
        case "FETCH_UNFULFILLED_INFO_BEGIN": 
        case "CREATE_STUDIO_BEGIN":
        case "FETCH_STUDENT_PURCHASES_BEGIN":   
        case "FETCH_STUDENT_SUBSCRIPTIONS_BEGIN":
            return {
                ...state,
                loading: true,
                error: false,
            };
        case "FETCH_USER_CLASSES_SUCCESS":
            return {
                ...state,
                userClasses: action.classes,
                loading: false,
                error: false,
            };

        case "FETCH_USER_CLASSES_ERROR":
            return {
                ...state,
                loading: false,
                error: "error getting user classes",
            };
        case "FETCH_STUDENT_PURCHASES_ERROR":
        case "FETCH_STUDENT_SUBSCRIPTIONS_ERROR": 
        return {
            ...state,
            loading: false,
            error: action.error,
        };

        case "FETCH_SINGLE_CLASS_SUCCESS":
            
            return {
                ...state,
                loading: false,
                error: false,
                currentClass: action.clazz,
            };
        case "REMOVE_CURRENT_CLASS_SUCCESS":
        //this removes the loaded current class from store so changing stores wouldn't splash the previously loaded data    
        return {
                ...state,
                loading: false,
                error: false,
                currentClass: {},
            };
        case "FETCH_SINGLE_CLASS_ERROR":
            //console.log("error getting class data");
            return state;

        case "EDIT_CLASS":
            return { ...state, currentClass: action.clazz };

        case "EDIT_CLASS_ERROR":
            //console.log("error saving class data", action.error);
            return state;

        case "FETCH_CLASS_STUDENTS_SUCCESS":
            return {
                ...state,
                currentClass: { ...state.currentClass, students: action.students },
                loading: false,
                error: false,
            };

        case "FETCH_CLASS_REQUIREMENTS_SUCCESS":
            return {
                ...state,
                currentClass: {
                    ...state.currentClass,
                    requirements: action.requirements,
                },
                loading: false,
                error: false,
            };
        case "SAVE_REQUIREMENT_SUCCESS":
            return {
                ...state,
                currentClass: {
                    ...state.currentClass,
                    requirements: [...state.currentClass.requirements, action.requirement],
                },
                loading: false,
                error: false,
            };

        case "SAVE_REQUIREMENT_ERROR":
            //console.log("error saving class requirement");
            return {
                ...state,
                loading: false,
                error: "error saving class requirement",
            };

        case "UPDATE_REQUIREMENT_SUCCESS":
            return {
                ...state,
                currentClass: {
                    ...state.currentClass,
                    requirements: state.currentClass.requirements.map((item, index) => {
                        if (item.id !== action.requirement.id) {
                            // This isn't the item we care about - keep it as-is
                            return item;
                        }
                        // Otherwise, this is the one we want - return an updated value
                        return {
                            ...item,
                            ...action.requirement,
                        };
                    }),
                },
                loading: false,
                error: false,
            };
        case "UPDATE_REQUIREMENT_ERROR":
            //console.log("error updating class requirement");
            return {
                ...state,
                loading: false,
                error: "error updating class requirement",
            };

        case "DELETE_REQUIREMENT_SUCCESS":
            //console.log(action);
            return {
                ...state,
                currentClass: {
                    ...state.currentClass,
                    requirements: state.currentClass.requirements.filter((item, index) => item.id !== action.id),
                },
                loading: false,
                error: false,
            };
        case "DELETE_REQUIREMENT_ERROR":
            //console.log("error updating class requirement", action.error);
            return {
                ...state,
                loading: false,
                error: "error updating class requirement",
            };
        case "FETCH_CLASS_RESOURCES_SUCCESS":
            return {
                ...state,
                currentClass: {
                    ...state.currentClass,
                    resources: action.resources,
                },
                loading: false,
                error: false,
            };
        case "SAVE_RESOURCE_SUCCESS":
            return {
                ...state,
                currentClass: {
                    ...state.currentClass,
                    resources: [...state.currentClass.resources, action.resource],
                },
                loading: false,
                error: false,
            };

        case "SAVE_RESOURCE_ERROR":
            //console.log("error saving class resource");
            return {
                ...state,
                loading: false,
                error: "error saving class resource",
            };

        case "UPDATE_RESOURCE_SUCCESS":
            return {
                ...state,
                currentClass: {
                    ...state.currentClass,
                    resources: state.currentClass.resources.map((item, index) => {
                        if (item.id !== action.resource.id) {
                            // This isn't the item we care about - keep it as-is
                            return item;
                        }
                        // Otherwise, this is the one we want - return an updated value
                        return {
                            ...item,
                            ...action.resource,
                        };
                    }),
                },
                loading: false,
                error: false,
            };
        case "UPDATE_RESOURCE_ERROR":
            //console.log("error updating class resource");
            return {
                ...state,
                loading: false,
                error: "error updating class resource",
            };

        case "DELETE_RESOURCE_SUCCESS":
            //console.log(action);
            return {
                ...state,
                currentClass: {
                    ...state.currentClass,
                    resources: state.currentClass.resources.filter((item, index) => item.id !== action.id),
                },
                loading: false,
                error: false,
            };
        case "DELETE_RESOURCE_ERROR":
            //console.log("error updating class resource", action.error);
            return {
                ...state,
                loading: false,
                error: "error updating class resource",
            };
        case "CLASS_INVITATIONS_START":
                return{
                    ...state,
                    invitationSent: false
                }
        case "CLASS_INVITATIONS_SUCCESS":
            return {
                ...state,
                invitationSent: true
            };
        case "CLASS_INVITATIONS_ERROR":
                return {
                    ...state,
                    invitationSent: false,
                    error : action.error
                };    
        case "FETCH_CLASS_PACKAGES_SUCCESS":
            return {
                ...state,
                currentClass: {
                    ...state.currentClass,
                    packages: action.packages,
                },
                loading: false,
                error: false,
            };
        case "SAVE_PACKAGE_SUCCESS":
            return {
                ...state,
                currentClass: {
                    ...state.currentClass,
                    packages: [...state.currentClass.packages, action.package],
                },
                loading: false,
                error: false,
            };

        case "SAVE_PACKAGE_ERROR":
            console.log("error saving class package");
            return {
                ...state,
                loading: false,
                error: "error saving class package",
            };

        case "UPDATE_PACKAGE_SUCCESS":
            return {
                ...state,
                currentClass: {
                    ...state.currentClass,
                    packages: state.currentClass.packages.map((item, index) => {
                        if (item.id !== action.package.id) {
                            // This isn't the item we care about - keep it as-is
                            return item;
                        }
                        // Otherwise, this is the one we want - return an updated value
                        return {
                            ...item,
                            ...action.package,
                        };
                    }),
                },
                loading: false,
                error: false,
            };
        case "UPDATE_PACKAGE_ERROR":
            console.log("error updating class package");
            return {
                ...state,
                loading: false,
                error: "error updating class package",
            };

        case "DELETE_PACKAGE_SUCCESS":
            console.log(action);
            return {
                ...state,
                currentClass: {
                    ...state.currentClass,
                    packages: state.currentClass.packages.filter((item, index) => item.id !== action.id),
                },
                loading: false,
                error: false,
            };
        case "DELETE_PACKAGE_ERROR":
            console.log("error updating class package", action.error);
            return {
                ...state,
                loading: false,
                error: "error updating class package",
            };        
                
        
        case "CREATE_BUY_PACKAGE_CHECKOUT_SESSION_SUCCESS":
            return Object.assign({}, state, {
                bookLessonStripeSession: action.result.data,
                loading: false,
            });
        case "CREATE_BUY_PACKAGE_CHECKOUT_SESSION_ERROR":
            return {
                ...state,
                loading: false,
                error: "There was a problem, please try again later.",
            };

        
        case "GET_CONNECTED_ACCOUNT_ID_SUCCESS":
                
            return Object.assign({}, state, {
                currentClass: {
                    ...state.currentClass,
                    teacher_account_id: action.result.data.account_id 
                },
                loading: false,
            });
        case "FETCH_UNFULFILLED_INFO_SUCCESS":
            return Object.assign({}, state, {
                currentClass: {
                    ...state.currentClass,
                    pendingPurchases: action.pendingPurchases,
                    metadata : {
                        ...state.currentClass.metadata,
                        pendingPurchasesCount : action.pendingPurchases ? Object.keys(action.pendingPurchases).length : 0
                    }
                },
                loading: false,
            });
        
        case "GET_CONNECTED_ACCOUNT_ID_ERROR":
            return {
                ...state,
                loading: false,
                error: "There was a problem, please try again later.",
            };
        case "GET_TEACHER_AVAILABLE_WEEKDAYS_BEGIN":
            return {
                ...state,
                teacher_available_weekdays_loading: true,
                error: false,
            };
        case "GET_TEACHER_AVAILABLE_WEEKDAYS_SUCCESS":
            return Object.assign({}, state, {
                currentClass: {
                    ...state.currentClass,
                    teacher_available_weekdays: action.result.data
                },
                teacher_available_weekdays_loading: false,
            });
        case "GET_TEACHER_AVAILABLE_WEEKDAYS_ERROR":
            console.log(action.error)
            return { ...state, teacher_available_weekdays_loading: false, error: action.error }; 
            
            case "GET_TEACHER_OPEN_SCHEDULE_BEGIN":
                return {
                    ...state,
                    teacher_open_schedule_loading: true,
                    error: false,
                };
            case "GET_TEACHER_OPEN_SCHEDULE_SUCCESS":
                return Object.assign({}, state, {
                    currentClass: {
                        ...state.currentClass,
                        teacher_open_schedule: action.result.data
                    },
                    teacher_open_schedule_loading: false,
                });
            case "GET_TEACHER_OPEN_SCHEDULE_ERROR":
                //console.log(action.error)
                return { ...state, teacher_open_schedule_loading: false, error: action.error }; 
            case "TO_FULLFIL_SUCCESS":
                return {
                    ...state,
                    currentClass: { ...state.currentClass, purchaseDatePrefCompleted: action.completed },
                    loading: false,
                    error: false,
                };    
            case "TO_FULLFIL_ERROR":
            case "CREATE_STUDIO_ERROR":
                //console.log("caught the error", action.error)
                return { ...state,
                    loading: false,
                    error: action.error
                }; 
            case "FETCH_STUDENT_PURCHASES_SUCCESS":
                return {
                    ...state,
                    currentClass: { 
                        ...state.currentClass, 
                        students: state.currentClass.students.map((student, i) => student.uid === action.purchases[0].metadata.uid ? {...student, purchases: action.purchases} : student) 
                    },
                    loading: false,
                    error: false,
                };
            case "FETCH_STUDENT_SUBSCRIPTIONS_SUCCESS":
                return {
                    ...state,
                    currentClass: { 
                        ...state.currentClass, 
                        students: state.currentClass.students.map((student, i) => student.uid === action.subscriptions[0].uid ? {...student, subscriptions: action.subscriptions} : student) 
                    },
                    loading: false,
                    error: false,
                }; 
        default:
            return state;
    }
};

export default classReducer;
